import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Tooltip from "components/Tooltip";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h1>

    <Playground __position={0} __code={'() => {\n  const [tooltipOpen, setTooltipOpen] = React.useState(false)\n  const toggle = () => setTooltipOpen(!tooltipOpen)\n  return (\n    <>\n      <p>\n        Somewhere in here is a{\' \'}\n        <span\n          style={{ textDecoration: \'underline\', color: \'blue\' }}\n          href=\"#\"\n          id=\"TooltipExample\"\n        >\n          tooltip\n        </span>\n        .\n      </p>\n      <Tooltip\n        placement=\"right\"\n        isOpen={tooltipOpen}\n        target=\"TooltipExample\"\n        toggle={toggle}\n      >\n        Hello world!\n      </Tooltip>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Tooltip,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [tooltipOpen, setTooltipOpen] = React.useState(false);

        const toggle = () => setTooltipOpen(!tooltipOpen);

        return <>
        <p>Somewhere in here is a <span style={{
              textDecoration: "underline",
              color: "blue"
            }} href="#" id="TooltipExample">tooltip</span>.</p>
        <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle} mdxType="Tooltip">
          Hello world!
        </Tooltip>
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/tooltips/" target="_blank">Reactstrap Tooltip</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      